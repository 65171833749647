<template>
  <div class="createDiscount">
    <div class="title">
      {{
        type == "add" || type == "copy"
          ? "建立新的折扣活动"
          : type == "edit"
          ? "编辑折扣活动"
          : "折扣活动详情"
      }}
    </div>
    <div class="cell1">
      <div class="titles">基本信息</div>
      <div class="form">
        <div class="form-item">
          <div style="width: 120px; margin-top: -20px">折扣促销名称</div>
          <div>
            <el-input
              size="small"
              style="width: 500px"
              v-model="form.discount_name"
              placeholder="请输入"
              maxlength="150"
              show-word-limit
            ></el-input>
            <div style="font-size: 12px; margin-top: 4px; color: #b0b0b0">
              折扣促销名称将不会展示给买家。
            </div>
          </div>
        </div>
        <div class="form-item">
          <div style="width: 120px; margin-top: -20px">折扣促销时期</div>
          <div>
            <el-date-picker
              size="small"
              style="width: 220px"
              v-model="start_time"
              prefix-icon="el-icon-date"
              type="datetime"
              placeholder="选择开始时间"
              value-format="timestamp"
              :disabled="state == '进行中的活动'"
            >
            </el-date-picker>
            <span style="padding: 0 20px">—</span>
            <el-date-picker
              size="small"
              style="width: 220px"
              prefix-icon="el-icon-date"
              v-model="end_time"
              type="datetime"
              placeholder="选择结束时间"
              default-time="23:59:59"
              value-format="timestamp"
            >
            </el-date-picker>
            <div style="font-size: 12px; margin-top: 4px; color: #b0b0b0">
              促销期间必须大于当前时间
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cell2">
      <div class="titles">
        <div>折扣促销商品</div>
        <div>
          <el-button
            v-if="goodsList.length != 0"
            style="color: #ee4d2d; border-color: #ee4d2d"
            icon="el-icon-plus"
            size="small"
            plain
            @click="addGoods"
            >添加商品</el-button
          >
        </div>
      </div>
      <div
        style="font-size: 12px; color: #b0b0b0; margin-top: 6px"
        v-if="goodsList.length == 0"
      >
        添加商品至折扣促销活动并设置折扣价格。
      </div>
      <div
        style="font-size: 12px; color: #b0b0b0; margin-top: 10px"
        v-if="goodsList.length != 0"
      >
        共{{ goodsList.length }}件商品
      </div>
      <div style="margin-top: 20px">
        <el-button
          v-if="goodsList.length == 0"
          style="color: #ee4d2d; border-color: #ee4d2d"
          icon="el-icon-plus"
          size="small"
          plain
          @click="addGoods"
          >添加商品</el-button
        >
      </div>
      <!-- 添加后的列表 -->
      <div class="goods-list" v-if="goodsList.length != 0">
        <div class="goods-list_top">
          <div class="top-l">
            <div style="margin-right: 140px">
              <div>批次设定</div>
              <div style="font-size: 12px; margin-top: 4px">
                {{ checkedCities.length }}件商品已选
              </div>
            </div>
            <div style="display: flex; align-items: center">
              <div>
                <div>折扣</div>
                <div style="margin-top: 10px">
                  <el-input
                    style="width: 140px"
                    size="small"
                    v-model="percentage"
                    placeholder="1-99"
                  >
                    <template slot="append">%折扣</template>
                  </el-input>
                </div>
              </div>
              <div style="margin: 0 20px">
                <div>促销库存</div>
                <div
                  style="margin-top: 10px; display: flex; align-items: center"
                >
                  <el-select
                    style="width: 120px"
                    size="small"
                    v-model="inventory"
                    placeholder="请选择"
                    @change="invChangeAll"
                  >
                    <el-option label="不更新" :value="1"> </el-option>
                    <el-option label="无限制" :value="2"> </el-option>
                    <el-option label="设定限制" :value="3"> </el-option>
                  </el-select>
                  <el-input
                    style="width: 80px"
                    size="small"
                    v-if="inventory == 3"
                    v-model="inventoryVal"
                  ></el-input>
                </div>
              </div>
              <div>
                <div>每位买家限购数量为</div>
                <div style="margin-top: 10px">
                  <el-select
                    style="width: 120px"
                    size="small"
                    v-model="purchasing"
                    placeholder="请选择"
                    @change="purChangeAll"
                  >
                    <el-option label="不更新" :value="1"> </el-option>
                    <el-option label="无限制" :value="2"> </el-option>
                    <el-option label="设定限制" :value="3"> </el-option>
                  </el-select>
                  <el-input
                    style="width: 80px"
                    size="small"
                    v-if="purchasing == 3"
                    v-model="purchasingVal"
                  ></el-input>
                </div>
              </div>
            </div>
          </div>
          <div class="top-r">
            <el-button
              :disabled="percentage == ''"
              size="small"
              @click="handleUpdate"
              type="info"
              plain
              >更新全部</el-button
            >
            <el-button @click="handleDelAll" size="small" type="info" plain
              >删除</el-button
            >
          </div>
        </div>
        <div class="goods-list_head">
          <div style="width: 3%">
            <el-checkbox
              :indeterminate="isIndeterminate"
              v-model="checkAll"
              @change="handleCheckAllChange"
            >
            </el-checkbox>
          </div>
          <div style="width: 11%">商品名称</div>
          <div style="width: 11%">原价</div>
          <div style="width: 11%">售价</div>
          <div style="width: 11%">折扣</div>
          <div style="width: 11%">库存</div>
          <div style="width: 14%">促销库存</div>
          <div style="width: 14%">促销限购</div>
          <div style="width: 11%">开启/关闭</div>
          <div style="width: 3%; text-align: center">删除</div>
        </div>
        <el-checkbox-group
          v-model="checkedCities"
          @change="handleCheckedCitiesChange"
        >
          <div
            class="goods-list_content"
            v-for="(item, index) in goodsList"
            :key="item.id"
          >
            <div class="content-t">
              <div style="width: 3%">
                <el-checkbox :label="item.id">&ensp;</el-checkbox>
              </div>
              <div style="width: 68%; display: flex; align-items: center">
                <el-image
                  style="width: 40px; height: 40px; margin-right: 10px"
                  :src="item.image"
                  fit="fit"
                ></el-image>
                <div
                  class="single-hidden"
                  style="width: 300px; font-size: 14px"
                >
                  {{ item.title }}
                </div>
              </div>
              <div style="width: 14%">
                <el-select
                  size="small"
                  style="width: 100px"
                  v-model="item.buyer_limitType"
                  @change="purChange(item)"
                  placeholder="请选择"
                >
                  <el-option label="无限制" :value="1"> </el-option>
                  <el-option label="设定限制" :value="2"> </el-option>
                </el-select>
                <el-input
                  @input="change"
                  style="width: 60px"
                  size="small"
                  v-if="item.buyer_limitType == 2"
                  v-model="item.buyer_limit"
                ></el-input>
              </div>
              <div style="width: 11%">
                <el-switch
                  @change="changeKG(item)"
                  v-model="item.kg"
                  active-color="#13ce66"
                >
                </el-switch>
              </div>
              <div style="width: 3%">
                <div
                  style="
                    width: 36px;
                    height: 36px;
                    border: 1px solid #eee;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    font-size: 16px;
                    background: #fff;
                  "
                  @click="handleDel(index)"
                >
                  <i class="el-icon-delete"></i>
                </div>
              </div>
            </div>
            <div
              class="content-b"
              v-for="(val, i) in item.specification"
              :key="i"
            >
              <div style="width: 3%"></div>
              <div style="width: 11%; font-size: 14px">
                {{ val.difference }}
              </div>
              <div style="width: 11%; font-size: 14px">RM{{ val.price }}</div>
              <div style="width: 11%; display: flex; align-items: center">
                <el-input
                  style="width: 100%"
                  size="small"
                  @input="change"
                  placeholder="请输入"
                  v-model="val.zksj"
                >
                  <template slot="prepend">RM</template>
                </el-input>
                <span style="margin: 0 4px">或</span>
              </div>
              <div style="width: 11%">
                <el-input
                  @change="changeZK(val)"
                  @input="inputZK(val, $event)"
                  style="width: 80%"
                  size="small"
                  v-model="val.zkbl"
                  placeholder="1-99"
                >
                  <template slot="append">%折扣</template>
                </el-input>
              </div>
              <div style="width: 11%; color: #000; font-size: 14px">
                {{ val.stock }}
              </div>
              <div style="width: 14%">
                <el-select
                  style="width: 110px"
                  size="small"
                  v-model="val.cxStockType"
                  placeholder="请选择"
                  @change="invChange(val)"
                >
                  <el-option label="无限制" :value="1"> </el-option>
                  <el-option label="设定限制" :value="2"> </el-option>
                </el-select>
                <el-input
                  style="width: 70px"
                  size="small"
                  @input="change"
                  v-if="val.cxStockType == 2"
                  v-model="val.cxStock"
                ></el-input>
              </div>
              <div style="width: 14%"></div>
              <div style="width: 11%">
                <el-switch
                  @change="change"
                  v-model="val.switch"
                  active-color="#13ce66"
                >
                </el-switch>
              </div>
              <div style="width: 3%"></div>
            </div>
          </div>
        </el-checkbox-group>
      </div>
    </div>
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: right;
        margin-top: 20px;
      "
    >
      <el-button @click="toBack" style="width: 80px" size="small"
        >取消</el-button
      >
      <el-button
        style="width: 80px"
        type="primary"
        size="small"
        @click="addDiscount"
        >确认</el-button
      >
    </div>
    <MarketingGoods ref="goods" :show="dialogVisible"></MarketingGoods>
  </div>
</template>
<script>
import MarketingGoods from "@/components/goods/MarketingGoods.vue";
import { discountAction, discountFind } from "@/api/marketing.js";
export default {
  components: { MarketingGoods },
  data() {
    return {
      goodsList: [],
      dialogVisible: false,
      percentage: "", //批量折扣
      inventory: 1, //批量库存
      inventoryVal: "",
      purchasing: 1, //批量限购
      purchasingVal: "",
      start_time: "", //开始时间
      end_time: "", //结束时间
      value1: "",
      type: 1,
      form: {
        id: 0,
        discount_name: "",
        start_time: "",
        end_time: "",
        discount_goods: [],
      },

      // 全选
      checkAll: false,
      checkedCities: [],
      isIndeterminate: false,
      state: sessionStorage.KZTYPE,
    };
  },
  created() {
    this.type = sessionStorage.DISCOUNTTYPE;
    if (sessionStorage.DISCOUNTTYPE) {
      this.type = sessionStorage.DISCOUNTTYPE;
      this.$router.options.routes.forEach((item) => {
        if (item.name == "marketingCenters") {
          item.children.forEach((val) => {
            if (val.name == "createDiscount") {
              val.meta.bread[3].name =
                this.type == "edit"
                  ? "编辑折扣活动"
                  : this.type == "add" || this.type == "copy"
                  ? "建立新的折扣活动"
                  : "折扣活动详情";
            }
          });
        }
      });

      // 是否是编辑操作
      if (this.type == "edit" || this.type == "info" || this.type == "copy") {
        this.getInfo();
      }
    } else {
      this.$router.replace("coupons");
    }
  },
  methods: {
    getInfo() {
      discountFind({
        id: sessionStorage.DISCOUNTID,
      }).then((res) => {
        if (res.code == 1) {
          this.form = res.data;
          if (this.type == "copy") {
            this.form.id = 0;
          }
          this.start_time = this.form.start_time * 1000;
          this.end_time = this.form.end_time * 1000;
          let arr = [];
          this.form.discount_goods.forEach((item, index) => {
            this.checkedCities.push(item.goods_id);
            this.checkAll = true;
            arr.push({
              id: item.goods_id,
              image: item.goods_image,
              title: item.goods_name,
              buyer_limit: item.buyer_limit,
              buyer_limitType: item.buyer_limit == 0 ? 1 : 2,
              kg: item.status == 1 ? true : false,
              specification: [],
            });

            this.form.discount_goods[index].discountGoodsConfig.forEach(
              (val) => {
                arr[index].specification.push({
                  difference: val.difference ? val.difference : "-",
                  id: val.goods_sku_id,
                  cxStock: val.stock_limit,
                  cxStockType: val.stock_limit == 0 ? 1 : 2,
                  price: val.goods_price,
                  stock: val.stock,
                  zksj: val.discount_price,
                  zkbl: parseInt(val.discount_ratio),
                  switch: val.status == 1 ? true : false,
                });
              }
            );
          });
          this.goodsList = arr;
        }
      });
    },
    // 创建活动
    addDiscount() {
      if (!this.form.discount_name) {
        this.$message.error("请填写折扣促销名称");
        return false;
      }
      if (!this.start_time || !this.end_time) {
        this.$message.error("请选择开始/结束时间");
        return false;
      }
      this.form.start_time = this.start_time / 1000;
      this.form.end_time = this.end_time / 1000;
      let arr = [];
      this.goodsList.forEach((item, index) => {
        arr.push({
          goods_id: item.id,
          buyer_limit: item.buyer_limit,
          status: item.kg ? 1 : 2,
          sku: [],
        });
        this.goodsList[index].specification.forEach((val) => {
          arr[index].sku.push({
            sku_id: val.id,
            discount_type: val.zkbl ? 2 : 1,
            discount: val.zkbl ? val.zkbl : val.zksj,
            stock_limit: val.cxStock,
            status: val.switch ? 1 : 2,
          });
        });
      });
      this.form.discount_goods = arr;
      discountAction({
        ...this.form,
      }).then((res) => {
        if (res.code == 1) {
          this.$message.success(
            this.type == "add" ? "创建折扣活动成功" : "编辑折扣活动成功"
          );
          this.$router.replace("discount");
        }
      });
    },
    handleClose() {},
    // 添加商品
    addGoods() {
      this.dialogVisible = true;
    },
    // 选中商品
    handleAndGoods(arr) {
      let list = [];
      this.goodsList.forEach((item) => {
        list.push(item.id);
      });
      arr.forEach((item) => {
        if (list.indexOf(item.id) == -1) {
          this.checkedCities.push(item.id);
          this.checkAll = true;
          item.buyer_limitType = 1;
          item.buyer_limit = 0;
          item.kg = true;

          if (item.specification.length == 0) {
            // 无规格商品
            item.specification.push({
              difference: "-",
              id: 0,
              price: item.price,
              sales: 0,
              sn: "",
              stock: item.stock,
              zksj: "",
              zkbl: "",
              cxStockType: 1,
              cxStock: 0,
              switch: true,
            });
          } else {
            item.specification.forEach((val) => {
              // 有规格商品
              val.zksj = "";
              val.zkbl = "";
              val.cxStockType = 1;
              val.cxStock = 0;
              val.switch = true;
            });
          }
          this.goodsList.push(item);
        }
      });

      // this.goodsList = arr;
      // this.goodsList.forEach((item) => {

      // });
      this.dialogVisible = false;
    },
    change() {
      this.$forceUpdate();
    },
    // 单规格售价
    changeZK(val) {
      if (val.zkbl) {
        val.zksj = ((val.price * val.zkbl) / 100).toFixed(2);
        this.$forceUpdate();
      } else {
        val.zksj = "";
      }
    },
    inputZK(item, val) {
      var reg = /^([1-9]\d|\d)$/;
      if (!reg.test(val) || val == 0) {
        item.zkbl = "";
      } else {
        item.zkbl = val;
      }
      this.$forceUpdate();
    },
    changeKG(val) {
      val.specification.forEach((item) => {
        if (val.kg) {
          item.switch = true;
        } else {
          item.switch = false;
        }
      });
      this.$forceUpdate();
    },
    // 批次设定库存
    invChangeAll() {
      if (this.inventory == 2) {
        this.inventoryVal = 0;
      } else {
        this.inventoryVal = "";
      }
    },
    // 批次设定限购
    purChangeAll() {
      if (this.purchasing == 2) {
        this.purchasingVal = 0;
      } else {
        this.purchasingVal = "";
      }
    },
    // 每个商品的促销限购
    purChange(val) {
      if (val.buyer_limitType == 1) {
        val.buyer_limit = 0;
      } else {
        val.buyer_limit = "";
      }
      this.$forceUpdate();
    },
    // 商品单个规格库存
    invChange(val) {
      if (val.cxStockType == 1) {
        val.cxStock = 0;
      } else {
        val.cxStock = "";
      }
      this.$forceUpdate();
    },
    // 更新全部
    handleUpdate() {
      this.goodsList.forEach((item) => {
        if (this.checkedCities.indexOf(item.id) != -1) {
          if (this.purchasing == 2) {
            // 限购数量无限制
            item.buyer_limitType = 1;
            item.buyer_limit = 0;
          }
          if (this.purchasing == 3) {
            // 限购数量设定限制
            item.buyer_limitType = 2;
            item.buyer_limit = this.purchasingVal;
          }
          item.specification.forEach((val) => {
            val.zksj = ((val.price * this.percentage) / 100).toFixed(2);
            val.zkbl = this.percentage;
            if (this.inventory == 2) {
              // 促销库存无限制
              val.cxStockType = 1;
              val.cxStock = 0;
            }
            if (this.inventory == 3) {
              // 促销库存设定限制
              val.cxStockType = 2;
              val.cxStock = this.inventoryVal;
            }
          });
        }
      });

      this.$forceUpdate();
    },

    // 全选
    handleCheckAllChange(val) {
      let arr = [];
      if (val) {
        this.goodsList.forEach((item) => {
          arr.push(item.id);
        });
      }

      this.checkedCities = arr;
      this.isIndeterminate = false;
    },

    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.goodsList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.goodsList.length;
    },

    // 删除
    handleDel(index) {
      if (this.checkedCities.indexOf(this.goodsList[index].id) != -1) {
        this.checkedCities.splice(
          this.checkedCities.indexOf(this.goodsList[index].id),
          1
        );
      }

      this.goodsList.splice(index, 1);
      this.$refs.goods.$data.checkedCities.splice(index, 1);
      this.handleCheckedCitiesChange(this.checkedCities);
    },
    handleDelAll() {
      let arr = this.goodsList.filter((item) => {
        if (this.checkedCities.indexOf(item.id) == -1) {
          return item;
        }
      });

      this.goodsList = arr;

      this.checkedCities = [];
      this.$refs.goods.$data.checkedCities = [];
      this.goodsList.forEach((item) => {
        this.$refs.goods.$data.checkedCities.push(item.id);
      });
      this.checkAll = false;
      this.isIndeterminate = false;
    },
    // 取消
    toBack() {
      this.$router.go(-1);
    },
  },
  watch: {
    percentage(newName) {
      var reg = /^([1-9]\d|\d)$/;
      if (!reg.test(newName) || newName == 0) {
        this.percentage = "";
      } else {
        this.percentage = newName;
      }
    },
  },
};
</script>
<style lang="less" >
.createDiscount {
  width: 1400px;
  margin: 0 auto;
  padding-top: 20px;
  .title {
    font-size: 20px;
  }
  .cell1 {
    padding: 20px;
    background: #fff;
    margin-top: 20px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
    .titles {
      font-size: 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .form {
      font-size: 14px;
      margin-top: 30px;
      .form-item {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        margin-left: 40px;
      }
    }
  }
  .cell2 {
    padding: 20px;
    background: #fff;
    margin-top: 20px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
    .titles {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .goods-list {
      font-size: 14px;
      color: #666;
      .goods-list_top {
        border: 1px solid #eee;
        padding: 20px;
        background: #f6f6f6;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 4px;
        .top-l {
          width: 70%;
          display: flex;
          align-items: center;
        }
      }
      .goods-list_head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        background: #f6f6f6;
        margin-top: 20px;
        border-radius: 4px;
        border: 1px solid #eee;
        font-size: 14px;
      }
      .goods-list_content {
        margin-top: 20px;
        border: 1px solid #eee;
        .content-t {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px 20px;
          background: #fafafa;
        }
        .content-b {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 20px 20px;
        }
      }
    }
  }
  .pop-list {
    font-size: 14px;
    border: 1px solid #eee;
    border-radius: 4px;
    .pop-list_head {
      display: flex;
      background: #f6f6f6;
      padding: 10px;
      justify-content: space-between;
    }
    .pop-list_content {
      display: flex;
      padding: 10px;
      justify-content: space-between;
    }
  }
  .el-input-group__append,
  .el-input-group__prepend {
    padding: 0 10px;
  }
}
</style>